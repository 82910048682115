const defaultState = () => ({
  loading: false,
  // ADDONS
  ssrBags: [],
  ssrOthers: [],
  seatMapData: [],
  error: null,
  isRetryLoading: false,
  isRetryLoadingSeatMap: false,
})

export default {
  namespaced: true,
  state: {
    ...defaultState(),
  },
  getters: {
    // ADDONS
    getSsrBags: state => state.ssrBags,
    getSsrOthers: state => state.ssrOthers,
    getSeatMapData: state => state.seatMapData,
    getLoading: state => state.loading,
    getError: state => state.error,
    getIsRetryLoading: state => state.isRetryLoading,
    getIsRetryLoadingSeatMap: state => state.isRetryLoadingSeatMap,
  },
  mutations: {
    RESET_CREATE_BOOKING_STORE(state) {
      Object.assign(state, { ...defaultState() })
    },
    // ADDONS
    SET_SSR_BAGS(state, bags) {
      state.ssrBags = bags
    },
    SET_SSR_OTHERS(state, others) {
      state.ssrOthers = others
    },
    SET_SEAT_MAP_DATA(state, seatMap) {
      state.seatMapData = seatMap
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_ERROR(state, error) {
      state.error = error
    },
    SET_IS_RETRY_LOADING(state, isRetryLoading) {
      state.isRetryLoading = isRetryLoading
    },
    SET_IS_RETRY_LOADING_SEAT_MAP(state, isRetryLoading) {
      state.isRetryLoadingSeatMap = isRetryLoading
    },
  },
  actions: {
    setSsrBags({ commit }, bags) {
      commit('SET_SSR_BAGS', bags)
    },
    setSsrOthers({ commit }, others) {
      commit('SET_SSR_OTHERS', others)
    },
    setSeatMapData({ commit }, seatMap) {
      commit('SET_SEAT_MAP_DATA', seatMap)
    },
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },
    setError({ commit }, error) {
      commit('SET_ERROR', error)
    },
    setIsRetryLoading({ commit }, isRetryLoading) {
      commit('SET_IS_RETRY_LOADING', isRetryLoading)
    },
    setIsRetryLoadingSeatMap({ commit }, isRetryLoading) {
      commit('SET_IS_RETRY_LOADING_SEAT_MAP', isRetryLoading)
    },
    resetCreateBookingStore({ commit }) {
      commit('RESET_CREATE_BOOKING_STORE')
    },
  },
}
